.forget-opt-form-fileds input {
  margin-right: 20px !important;
  margin-top: 30px;
  margin-bottom: 30px;
}
a.form-otp-resend {
  cursor: pointer;
}
.highlightOption {
  background: none !important;
  color: rgb(44 56 74 / 95%) !important;
}
.highlightOption:hover {
  color: #fff !important;
}
