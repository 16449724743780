// Here you can add other styles
.btn-dark-light {
  background: rgba(44, 56, 74, 0.95);
  border: 1px solid rgba(44, 56, 74, 0.95);
  color: #fff;
}
.btn-dark-light:hover {
  background: #3c4b64;
  border: 1px solid #3c4b64;
  color: #fff;
}
.btn-dark-light:focus:not(:focus-visible) {
  background: rgba(44, 56, 74, 0.95);
  border: 1px solid #3c4b64;
  color: #fff;
}
.bg-success-gradient {
  background-color: var(--cui-success, #1b9e3e);
  background-image: linear-gradient(
    45deg,
    var(--cui-success-start, #2eb85c) 0%,
    var(--cui-success-stop, #1b9e3e 100%)
  );
}
.bg-dark-gradient {
  background-color: var(--cui-dark, #212333);
  background-image: linear-gradient(
    45deg,
    var(--cui-dark-start, #3c4b64) 0%,
    var(--cui-dark-stop, #212333 100%)
  );
}
.bg-warning-gradient {
  background-color: var(--cui-warning, #f6960b);
  background-image: linear-gradient(
    45deg,
    var(--cui-warning-start, #f9b115) 0%,
    var(--cui-warning-stop, #f6960b 100%)
  );
}
.bg-danger-gradient {
  background-color: var(--cui-danger, #d93737);
  background-image: linear-gradient(
    45deg,
    var(--cui-danger-start, #e55353) 0%,
    var(--cui-danger-stop, #d93737 100%)
  );
}
.table svg.nav-icon {
  width: 1rem;
  height: 1rem;
}
.breadcrumb-item a {
  color: #8a93a2 !important;
  color: var(--cui-breadcrumb-active-color, #8a93a2);
}
.footer a {
  color: rgba(44, 56, 74, 0.95) !important;
}
html:not([dir='rtl']) .sidebar-nav .nav-group-items .nav-link .nav-icon {
  margin-left: -3rem !important;
}
.profile-card img,
.profile-card .avatar {
  width: 6rem;
  height: 6rem;
  font-size: 1.6rem;
}
// .profile-card {
//   height: 220px;
// }
/* */
.profile-input {
  // padding: 5px 10px;
  background: #fff;
  // border: 1px solid rgba(44, 56, 74, 0.95);
  position: relative;
  color: rgba(44, 56, 74, 0.95);
  border-radius: 2px;
  text-align: center;
  cursor: pointer;
  width: 150px;
  margin: 10px auto;
}

.hide_file {
  position: absolute;
  z-index: 1000;
  opacity: 0;
  cursor: pointer;
  right: 50%;
  top: 0%;
  height: 100%;
  width: 64%;
  margin: 0 auto;
  transform: translate(50%, 0%);
  background: aliceblue;
  border-radius: 50%;
}
.profile-upload-btn {
  padding: 5px 10px;
  background: rgba(44, 56, 74, 0.95);
  border: 1px solid rgba(44, 56, 74, 0.95);
  border-radius: 2px;
  color: #fff !important;
  text-align: center;
  cursor: pointer;
  width: 150px;
  // margin: 10px auto;
}
/* multiselect input option*/
.chip,
.highlightOption {
  background: #636f83 !important;
}
.multiSelectContainer li:hover {
  background: #636f83 !important;
}
.chip {
  margin-bottom: 0px !important;
  padding: 3px 10px !important;
}
.multiSelectContainer input {
  margin: 0px !important;
}
.form-control:focus {
  box-shadow: unset !important;
}
.search-wrapper {
  border: 1px solid var(--cui-input-border-color, #b1b7c1) !important;
}

//movie-detail-page

.banner-card img.preview {
  width: 100%;
}

//add-coupon

.coupon-code {
  position: relative;
}
.generate-code {
  position: absolute;
  top: 35px;
  right: 16px;
}

//Error

.error-text {
  color: var(--cui-danger, #e55353) !important;
}

.multi-select .css-1s2u09g-control {
  border: 1px solid var(--cui-input-border-color, #b1b7c1);
}

.css-1pahdxg-control:hover {
  border: 1px solid var(--cui-input-border-color, #b1b7c1) !important;
}
.css-1pahdxg-control:focus {
  border: 1px solid var(--cui-input-focus-border-color, #998fed) !important;
}
.css-1pahdxg-control {
  box-shadow: none !important;
}
//Sidebar

.sidebar-brand .avatar-img {
  width: 53px !important;
}
//responsive
@media screen and (max-width: 640px) {
  .list .position-relative {
    overflow-x: scroll;
  }
  .intro-card {
    width: 100% !important;
  }
  .footer {
    margin-top: 30px;
  }
  .body.flex-grow-1.px-3 {
    padding: 0px !important;
  }
  .position-relative .table {
    width: 180vh !important;
  }
}
// password eyes
.pass-eyes-outer {
  position: relative;
}
.pass-show-eye {
  position: absolute;
  top: 33px;
  right: 9px;
  cursor: pointer;
  padding: 6px 10px;
  background: #ddd;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-left: 1px solid #b1b7c1;
}
.show-pass {
  padding-right: 40px;
}
//profile.css
.avatar.preview img {
  object-fit: cover;
  object-position: center center;
}

/*multi select options css  */
.searchWrapper {
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  overflow: hidden !important;
}

/*multi select options css end */
input#duration::-webkit-datetime-edit-ampm-field {
  display: none !important;
}
// banner preview

.banner-preview-outer {
  max-height: 120px;
  overflow: auto;
}
.banner-preview-outer .remove-bnr {
  position: absolute;
  top: 3px;
  right: 3px;
  font-size: 22px;
  line-height: 15px;
  padding: 2px 1px;
  cursor: pointer;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  text-align: center;
  color: #636f83;
  background: #fff;
}
.banner-preview-outer .remove-bnr:hover {
  opacity: 0.9;
}
.subtitle-card {
  color: #636f83;
  font-size: 12px;
  padding-right: 36px;
  border: 1px solid #636f83;
}
.dash-header .avatar-img {
  width: 100%;
  height: 100%;
  border-radius: 50em;
  object-fit: cover;
  object-position: center center;
}

.flex{
  display: flex;
}

.font-size-18{
  font-size: 18px;
}

.cursor-pointer{
  cursor: pointer;
}

.horizontal-scroll-table {
  width: 100%;
  overflow-x: auto;
}
